import PropTypes from 'prop-types'
import styled from 'styled-components';
import React from 'react'
import { Link } from "gatsby"

import Icon from './icon'

const FooterBlock = styled('footer')`
    height: 270px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 80px 100px;
    background: #000000;
    @media(max-width: 1024px) {
        height: 160px;
        padding: calc(20px + (50 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (1024 - 320)))
    }
    @media(max-width: 768px) {
        display: block;
        .sections {
            margin-bottom: 30px;
        }
    }
    .sections {
        min-width: 33%;
        &.location {
            .details {
                a {
                    display: block;
                }
            }
        }
        .header {
            color: #999999;
            font-size: calc(20px + (50 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
            font-family: HaraBaraMais-Bold;
            margin-bottom: calc(20px + (35 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
            @media (max-width: 1024px) {
                font-size: calc(20px + (50 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
                margin-bottom: calc(20px + (35 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
            }
            @media (max-width: 768px) {
                font-size: calc(20px + (50 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
                margin-bottom: calc(20px + (35 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
            }
        }
        .details {
            a, p {
                cursor: pointer;
                font-size: calc(12px + (18 - 12) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
                color: #ffffff;
                font-family: SFUIText-Medium;
                text-decoration: none;
                transition: all 250ms ease-in-out;
                margin-right: 20px;
                margin-bottom: 10px;
                display: inline-block;
                @media (max-width: 1024px) {
                    font-size: calc(12px + (18 - 12) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
                }
                @media (max-width: 768px) {
                    font-size: calc(12px + (18 - 12) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
                }
                &:hover {
                    color: #f1526f;
                    svg path {
                        fill: #f1526f;
                    }
                }
                ${Icon.Element} {
                    margin-right: 5px;
                    svg path {
                        transition: all 250ms ease-in-out;
                    }
                }
            }
            .copyright {
                font-size: 12px;
                color: #999999;
                font-family: SFUIText-Regular;
                margin-top: 20px;
                padding-bottom: 20px;
            }
        }
    }
`

const openForm = () => {
    var form = document.getElementsByClassName('contact-us')[0];
    form.classList.remove('inactive');
    form.classList.add('active');
}

const Footer = () => (
  <FooterBlock>
    <div className='sections location'>
        <p className='header'>Locations</p>
        <div className='details'>
            <a title="Location Mumbai" href="https://goo.gl/maps/aHQertmoPKCgf1Pj6" rel="noopener noreferrer" target='_blank'>
                <Icon name="location" />
                Mumbai
            </a>
            <a title="Location Hyderabad" href="https://goo.gl/maps/eQP1xFDVGzvo3MxB7" rel="noopener noreferrer" target='_blank'>
                <Icon name="location" />
                Hyderabad
            </a>
        </div>
    </div>
    <div className='sections company'>
        <p className='header'>Company</p>
        <div className='details'>
            <Link title="About Us" to="/#about">About Us</Link>
            <Link title="Product" to="/#products">Product</Link>
            <Link title="Services" to="/#services">Services</Link>
            <p className="contact-btn" role="presentation" onClick={() => openForm()}>Get In Touch</p>
            <Link title="Privacy Policy" to="/privacy-policy">Privacy Policy</Link>
        </div>
    </div>
    <div className='sections connect'>
        <p className='header'>Connect with us</p>
        <div className='details'>
            <div className='social-icons'>
                {/* <a href='/'>
                    <Icon name='facebook'/>
                </a> */}
                <a
                    href="https://www.instagram.com/desynovadigital/"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Instagram"
                >
                    <Icon name='instagram'/>
                </a>
                <a
                    href='https://www.linkedin.com/company/20445021'
                    rel="noopener noreferrer"
                    target="_blank"
                    title="Linked In"
                >
                    <Icon name='linkedin'/>
                </a>
                {/* <a href='/'>
                    <Icon name='medium'/>
                </a> */}
            </div>
            { /*<div className='copyright'>Copyright © 2021 Desynova Digital Pvt. Ltd.</div> */}
        </div>
    </div>
  </FooterBlock>
)

Footer.propTypes = {
    type: PropTypes.string,
}

Footer.defaultProps = {
    type: 'normal',
}

export default Footer
