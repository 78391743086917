/* eslint-disable jsx-a11y/label-has-associated-control */
import styled from 'styled-components';
import React, { Component } from 'react';
import Icon from './icon';

const ContactContainer = styled('section')`
  position: fixed;
  z-index: 9999;
  top: -100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  &.inactive {
    top: -100%;
    transition: 250ms all ease-in-out 950ms;
    .contact-block {
      opacity: 0;
      transform: translateY(10px);
      transition: 550ms all ease-in-out;
    }
    .background-effect {
      transform: skewY(8deg) translateY(-100%);
      height: 0;
      transition: 550ms all ease-in-out 600ms;
    }
  }
  &.active {
    top: 0%;
    transition: 0ms all ease-in-out;
    z-index: 10001;
    .contact-block {
      opacity: 1;
      transform: translate(0);
      transition: 550ms all ease-in-out 600ms;
    }
    .background-effect {
      transform: skewY(0deg) translateY(0%);
      height: 100%;
      transition: 550ms all ease-in-out;
    }
  }
  .background-effect {
    position:absolute;
    height:0%;
    width:100%;
    background: #f1526f;
    transform: skewY(8deg) translateY(-100%);
  }
  .contact-block {
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    position: relative;
    padding: calc(
      20px + (200 - 20) *
        (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320))
    );
    opacity: 0;
    transform: translateY(10px);
    .close-btn {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 40px;
      right: 100px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      transition: all 250ms ease-in-out;
      cursor: pointer;
      &:hover {
        box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);
      }
      ${Icon.Element} {
        line-height: 0.1;
      }
      @media (max-width: 768px) {
        width: 30px;
        height: 30px;
        top: 20px;
        right: 20px;
        svg {
          width: 10px;
          height: 10px
        }
      }
    }
    .section-heading {
      font-size: calc(40px + (148 - 40) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
      font-family: HarabaraMais-Bold;
      color: #fff;
      word-spacing: -10px;
      line-height: 1;
      margin-bottom: calc(30px + (110 - 30) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
    }
    .section-details-block {
      display: flex;
      .section-details {
        width: 50%;
        .sections {
          &.location {
            margin-bottom: 50px;
            .details {
              .address {
                margin-bottom: 20px;
                .title {
                  color: rgba(255,255,255,0.8);
                  font-size: calc( 14px + (25 - 14) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)) );
                  font-family: SFUIText-Medium;
                  margin-bottom: 10px;
                }
                a {
                  display: flex;
                  justify-content: flex-start;
                  align-items: baseline;
                  width: 60%;
                }
              }
            }
          }
          .header {
            color: rgba(255, 255, 255, 0.8);
            font-size: calc(
              20px + (50 - 20) *
                (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320))
            );
            font-family: HaraBaraMais-Bold;
            margin-bottom: calc(
              20px + (35 - 20) *
                (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320))
            );
            @media (max-width: 1024px) {
              font-size: calc(
                20px + (50 - 20) *
                  (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320))
              );
              margin-bottom: calc(
                20px + (35 - 20) *
                  (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320))
              );
            }
            @media (max-width: 768px) {
              font-size: calc(
                20px + (50 - 20) *
                  (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320))
              );
              margin-bottom: calc(
                20px + (35 - 20) *
                  (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320))
              );
            }
          }
          .details {
            a {
              font-size: calc(
                12px + (18 - 12) *
                  (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320))
              );
              color: #ffffff;
              font-family: SFUIText-Medium;
              text-decoration: none;
              transition: all 250ms ease-in-out;
              margin-right: 20px;
              margin-bottom: 10px;
              @media (max-width: 1024px) {
                font-size: calc(
                  12px + (18 - 12) *
                    (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320))
                );
              }
              @media (max-width: 768px) {
                font-size: calc(
                  12px + (18 - 12) *
                    (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320))
                );
              }
              &:hover {
                color: #000000;
                svg path {
                  fill: #000000;
                }
              }
              ${Icon.Element} {
                margin-right: 10px;
                svg path {
                  transition: all 250ms ease-in-out;
                }
              }
            }
          }
        }
        @media (max-width: 768px) {
          width: 100%;
          &.block-left {
            display: none;
          }
        }
      }
    }
    .contact-form {
      max-width: 600px;
      width: 100%;
      margin: 0 auto;
      z-index: 1;
      #contactus-form .form-field {
        position: relative;
        input, textarea {
          border: none;
          border-radius: 0;
          width: 100%;
          padding: 15px 0 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #fff;
          color: #fff;
          font-family: SFUIText-Light;
          font-size: 14px;
          outline: 0 !important;
          background: 0 0;
          resize: none;
          -webkit-appearance: none;
        }
        input {
          &:focus ~ label, &:valid ~ label {
            top: -3px;
            font-size: 10px;
            color: #000000;
            left: 0;
            font-weight: 700;
          }
        }
        textarea {
          &:focus ~ label, &:valid ~ label {
            top: -3px;
            font-size: 10px;
            color: #000000;
            left: 0;
            font-weight: 700;
          }
        }
        .bar {
          position: relative;
          display: block;
          width: 100%;
          &:after {
            content: '';
            height: 2px;
            width: 0;
            bottom: 10px;
            position: absolute;
            background: #000000;
            transition: .2s ease all;
            -moz-transition: .2s ease all;
            -webkit-transition: .2s ease all;
          }
          &:before {
            content: '';
            height: 2px;
            width: 0;
            bottom: 10px;
            position: absolute;
            background: #000000;
            transition: .2s ease all;
            -moz-transition: .2s ease all;
            -webkit-transition: .2s ease all;
            left: 50%;
          }
          &:after {
            right: 50%;
          }
        }
        input:focus ~ .bar {
          &:after, &:before {
            width: 50%;
          }
        }
        .highlight {
          position: absolute;
          height: 60%;
          width: 100px;
          top: 25%;
          left: 0;
          pointer-events: none;
          opacity: .5;
        }
        input:focus ~ .highlight {
          -webkit-animation: inputHighlighter .3s ease;
          -moz-animation: inputHighlighter .3s ease;
          animation: inputHighlighter .3s ease;
        }
        label {
          position: absolute;
          top: 18px;
          left: 0;
          color: #fff;
          pointer-events: none;
          font-family: SFUIText-Light;
          font-size: 12px;
          transition: .2s ease all;
          -moz-transition: .2s ease all;
          -webkit-transition: .2s ease all;
          text-transform: uppercase;
        }
      }
      .form-submit-btn {
        text-align: left;
        .contact-form-msg {
          font-size: 14px;
          font-family: SFUIText-Light;
          color: #fff;
        }
        .contact-btn {
          border-radius: 2px;
          cursor: pointer;
          padding: 18px calc(28px + (40 - 28) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
          text-align: center;
          font-size: 14px;
          text-transform: uppercase;
          font-family: SFUIText-Medium;
          display: inline-block;
          margin-top: 20px;
          background: #fff;
          color: #f1526f;
          transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
          position: relative;
          z-index: 0;
          &:hover {
            background:0 0;
            color: #fff;
          }
          &:before {
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            height: 0;
            width: 100%;
            z-index: -1;
            content: '';
            background: #000;
            transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
          }
          &:hover:before {
            top: 0;
            bottom: auto;
            height: 100%;
          }
        }
      }
    }
    @media (max-width: 1024px) {
      padding: calc(
        20px + (50 - 20) *
          (((var(--text-scale-unit) * 100) - 320px) / (1024 - 320))
      );
    }
    @media (max-width: 768px) {
      padding: 70px 20px;
    }
  }
`;
class Contact extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        name: '',
        email: '',
        subject: '',
        message: '',
        formMsg: '',
        loading: false
      },
    };
    this.carouselInterval = null;
  }

  onInputChange(event, type) {
    const {value} = event.target;
    this.setState((state) =>({
      formData: {
        ...state.formData,
        [type]: value
      }
    }));
  }

  closeForm() {
    const form = document.getElementsByClassName('contact-us')[0];
    form.classList.remove('active');
    form.classList.add('inactive');
    this.setState({
      formData: {
        name: '',
        email: '',
        subject: '',
        message: '',
        formMsg: '',
        loading: false
      }
    });
  }

  submitForm() {
    const { formData: {name, email, subject, message} } = this.state;
    const self = this;
    if(name && email && subject && message) {
      this.setState((state) => ({
        formData: {
          ...state.formData,
          formMsg: 'Please wait ...',
          loading: true
        }
      }));
      fetch(`https://script.google.com/macros/s/AKfycbzS4kE0wTF9ZXNJSK6QIA6BJDCkFQmgTftCQFggoLc5_j2RJOE/exec?name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}&subject=${encodeURIComponent(subject)}&message=${encodeURIComponent(message)}`, {
        method: 'GET'
      })
        .then(function(res){ return res.json(); })
        .then(function(data){
          if(data.result === 'success'){
            self.setState({
              formData: {
                name: '',
                email: '',
                subject: '',
                message: '',
                formMsg: 'Request Submitted',
                loading: false
              }
            });
          }
        });
    } else {
      this.setState((state) => ({
        formData: {
          ...state.formData,
          formMsg: 'All Fields are Mandatory'
        }
      }));
    }
  }

  render() {
    const { formData } = this.state;

    return (
      <ContactContainer
        className='contact-us inactive'
      >
        <div className='background-effect' />
        <div className='contact-block'>
          <div
            role="presentation"
            onClick={() => this.closeForm()}
            className="close-btn">
            <Icon name="cross" color="#f1526f" />
          </div>
          <div className="section-heading">Bring yourself <br /> to the front row</div>
          <div className="section-details-block">
            <div className="section-details block-left">
              <div className="sections location">
                <p className="header">Locations</p>
                <div className="details">
                  <div className='address'>
                    <p className='title'>Mumbai</p>
                    <a
                      title="Location Mumbai"
                      href="https://goo.gl/maps/aHQertmoPKCgf1Pj6"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Icon name="location" />
                      <p>5A, Goldline Business Center, New Link Road, Malad West, Mumbai, Maharashtra 400064</p>
                    </a>
                  </div>
                  <div className='address'>
                    <p className='title'>Hyderabad</p>
                    <a
                      href="https://goo.gl/maps/eQP1xFDVGzvo3MxB7"
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Location Hyderabad"
                    >
                      <Icon name="location" />
                      <p>Incor 9, 2nd floor, Kavuri Hills, Madhapur, Hyderabad, Telangana 500033</p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="sections connect">
                <p className="header">Connect with us</p>
                <div className="details">
                  <div className="social-icons">
                    {/* <a href="/">
                      <Icon name="facebook" />
                    </a> */}
                    <a
                      href="https://www.instagram.com/desynovadigital/"
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Instagram"
                    >
                      <Icon name="instagram" />
                    </a>
                    <a
                      href='https://www.linkedin.com/company/20445021'
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Linked In"
                    >
                      <Icon name="linkedin" />
                    </a>
                    {/* <a href="/">
                      <Icon name="medium" />
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="section-details block-right">
              <div className="contact-form lazy-load">
                <form id="contactus-form">
                  <div className="form-field name-field">
                    <input id="name" onChange={(event) => this.onInputChange(event, 'name')} value={formData.name} type="text" name="name" required />
                    <span className="highlight" />
                    <span className="bar" />
                    <label htmlFor="name">Name</label>
                  </div>
                  <div className="form-field email-field">
                    <input id="email" onChange={(event) => this.onInputChange(event, 'email')} value={formData.email} type="text" name="email" required />
                    <span className="highlight" />
                    <span className="bar" />
                    <label htmlFor="email">Email</label>
                  </div>
                  <div className="form-field name-field">
                    <input id="subject" onChange={(event) => this.onInputChange(event, 'subject')} value={formData.subject} type="text" name="subject" required />
                    <span className="highlight" />
                    <span className="bar" />
                    <label htmlFor="subject">Subject</label>
                  </div>
                  <div className="form-field message-field">
                    <textarea id="message" onChange={(event) => this.onInputChange(event, 'message')} value={formData.message} type="text" name="message" required />
                    <span className="highlight" />
                    <span className="bar" />
                    <label htmlFor="message">Message</label>
                  </div>
                </form>
                <div className="form-submit-btn">
                  <p className="contact-form-msg">{formData.formMsg}</p>
                  <p style={formData.loading ? {pointerEvents: 'none', cursor: 'not-allowed'} : {pointerEvents: 'all', cursor: 'pointer'} } className="contact-btn" id="submit-form" role="presentation" onClick={() => this.submitForm()}>
                    GET IN TOUCH
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContactContainer>
    );
  }

}

export default Contact;
