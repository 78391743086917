/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import Footer from './footer';
import './layout.css';

const Layout = ({ children, footerType }) => {
  const { dataJson: { siteMetadata } } = useStaticQuery(
    graphql`
      query seoTitle {
        dataJson {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  return (
    <>
      <Header siteTitle={siteMetadata.title} />
      <div
        style={{
          margin: '0 auto',
          padding: '0px'
        }}
      >
        <main>{children}</main>
      </div>
      <Footer type={footerType} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  footerType: PropTypes.string
};

export default Layout;
