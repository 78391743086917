/**
 * SEOComponent component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEOComponent({ description, lang, meta, title }) {
  const { dataJson: { siteMetadata } } = useStaticQuery(
    graphql`
      query seo {
        dataJson {
          siteMetadata {
            title
            description
            author
            keywords
            twitterTitle
            sitename
            url
            src {
              publicURL
            }
          }
        }
      }
    `
  );

  const metaDescription = description || siteMetadata.description;
  const metaTitle = title || siteMetadata.title;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate="%s"
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: siteMetadata.keywords,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'og:image',
          content: siteMetadata.url + siteMetadata.src.publicURL
        },
        {
          name: 'og:site_name',
          content: siteMetadata.sitename
        },
        {
          name: 'og:url',
          content: siteMetadata.url
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:creator',
          content: siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: siteMetadata.twitterTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:image',
          content: siteMetadata.url + siteMetadata.src.publicURL
        }
      ].concat(meta)}
    />
  );
}

SEOComponent.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
};

SEOComponent.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEOComponent;
