import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { icons } from './icons'

const Icon = props => {
  // If the icon name isn't found, show a question mark instead.
  const icon = icons[props.name] || icons['default']

  return (
    <Icon.Element>
      <Image
        width={props.width || icon.width}
        height={props.height || icon.height}
        viewBox={`0 0 ${icon.width} ${icon.height}`}
        color={props.color}
      >
        {icon.paths.map((path, index) => {
          return (
            <path
              key={index}
              d={path}
              transform={icon.transform}
              fill={props.color}
              stroke={props.stroke}
              strokeWidth={icon.strokeWidth}
              fillRule={icon.fillRule}
            />
          )
        })}
      </Image>
    </Icon.Element>
  )
}

Icon.Element = styled.i`
  display: inline-block;
  line-height: 1;
`
const Image = styled.svg`
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  path {
    fill: ${props => props.color};
  }
`

const __ICONNAMES__ = Object.keys(icons)

Icon.propTypes = {
  /** Icon name */
  name: PropTypes.oneOf(__ICONNAMES__).isRequired,
  /** Icon size */
  width: PropTypes.number,
  height: PropTypes.number,
  /** Icon foreground color */
  color: PropTypes.string,
  stroke: PropTypes.string
}

Icon.defaultProps = {
  name: 'default',
  color: '#fff'
}

export default Icon
export { __ICONNAMES__ }
