import PropTypes from "prop-types"
import styled from "styled-components"
import React from "react"
import { Link } from "gatsby"

import Contact from "./contact"

const HeaderBlock = styled("header")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  position: relative;
  width: 100%;
  max-width: 100vw;
  left: 50%;
  top: 0;
  box-sizing: border-box;
  background: rgba(0, 0, 0);
  z-index: 10000;
  transform: translateX(-50%);
  padding: 40px
    calc(
      20px + (200 - 20) *
        (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320))
    );
  height: 110px;
  @media (max-width: 1024px) {
    padding: 25px
      calc(
        20px + (50 - 20) *
          (((var(--text-scale-unit) * 100) - 320px) / (1024 - 320))
      );
    height: 80px;
  }
  @media (max-width: 768px) {
    padding: 25px
      calc(
        20px + (20 - 20) *
          (((var(--text-scale-unit) * 100) - 320px) / (768 - 320))
      );
  }
  .header-links-list nav {
    display: flex;
    a, p {
      text-decoration: none;
      color: #fff !important;
      font-size: 14px;
      font-family: SFUIText-Medium;
      padding: 0 20px;
      transition: all 0.4s cubic-bezier(0.215,0.61,0.355,1) 0s;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        color: #f1526f !important;
      }
    }
    .contact-btn {
      border-radius: 2px;
      cursor: pointer;
      padding: 14px 20px;
      text-align: center;
      font-size: 14px;
      font-family: SFUIText-Medium;
      display: inline-block;
      margin-left: 20px;
      background: #f1526f;
      color: #fff;
      transition: all 0.4s cubic-bezier(0.215,0.61,0.355,1) 0s;
      position: relative;
      z-index: 0;
      &:hover {
        background:0 0;
        color: #f1526f;
      }
      &:before {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 0;
        width: 100%;
        z-index: -1;
        content: '';
        background: #fff;
        transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
      }
      &:hover:before {
        top: 0;
        bottom: auto;
        height: 100%;
      }
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
  .menu-hamburger {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
    .nav-block {
      min-width: 100%;
      position: absolute;
      background: #f1526f;
      right: 0;
      top: 0;
      height: 0;
      padding: 0 20px;
      box-sizing: border-box;
      overflow: hidden;
      transition: all 450ms ease-in-out;
      nav a, nav p {
        display: block;
        padding: 6px 0;
        color: #fff;
        font-size: 14px;
        text-decoration: none;
        font-family: SFUIText-Light;
        transition: all 1s ease-in-out;
        opacity: 0;
        top: 15px;
        position: relative;
      }
    }
    .menu-line-icon {
      width: 20px;
      height: 16px;
      overflow: hidden;
      z-index: 10;
      position: relative;
      cursor: pointer;
      .line-1,
      .line-2,
      .line-3 {
        width: 20px;
        height: 2px;
        background: #fff;
        margin-bottom: 4px;
        transition: all 0.45s ease-in-out;
        position: relative;
      }
      .line-1,
      .line-3 {
        top: 0;
        left: 0;
      }
    }
    &.active {
      .nav-block {
        padding: 20px;
        height: 203px;
        nav {
          width: 90%;
        }
      }
      .menu-line-icon {
        .line-2 {
          width: 0;
        }
        .line-1 {
          transform: rotate(45deg);
          top: 6px;
          left: -3px;
        }
        .line-3 {
          transform: rotate(-45deg);
          top: -6px;
          left: -3px;
        }
      }
    }
    .nav-block .nav-header {
      position: relative;
      width: 100%;
      font-family: HarabaraMais-Bold;
      font-size: 20px;
      margin-bottom: 10px;
      color: #fff;
      transition: all 1s ease-in-out;
      opacity: 0;
      top: 15px;
    }
    &.active .nav-block {
      .nav-header {
        top: 0;
        opacity: 1;
      }
      nav a, nav p {
        opacity: 1;
        top: 0;
        padding: 5px 0;
      }
    }
  }
  img.header-logo {
    line-height: 1;
    width: calc(
      120px + (300 - 120) *
        (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320))
    );
    @media (max-width: 1024px) {
      width: calc(
        120px + (250 - 120) *
          (((var(--text-scale-unit) * 100) - 320px) / (1024 - 320))
      );
    }
    @media (max-width: 768px) {
      width: calc(
        120px + (250 - 120) *
          (((var(--text-scale-unit) * 100) - 320px) / (768 - 320))
      );
    }
  }
`

const openMenu = () => {
  var menu = document.getElementsByClassName("menu-hamburger")[0]
  if (menu.classList.contains("active")) {
    menu.classList.remove("active")
  } else {
    menu.classList.add("active")
  }
}

const openForm = () => {
  var form = document.getElementsByClassName('contact-us')[0];
  form.classList.remove('inactive');
  form.classList.add('active');
}


const Header = () => (
  <React.Fragment>
    <Contact />
    <HeaderBlock>
      <Link title="Home" to="/">
        <img
          className="header-logo"
          src={"/images/header-logo.svg"}
          alt="Logo"
        />
      </Link>
      <div className="header-links-list">
        <nav>
          <Link title='About Us' to="/#about">About Us</Link>
          <Link title='Product' to="/#products">Product</Link>
          <Link title='Services' to="/#services">Services</Link>
          <p className="contact-btn" role="presentation" onClick={() => openForm()}>Get In Touch</p>
        </nav>
      </div>
      <div role="presentation" className="menu-hamburger" onClick={() => openMenu()}>
        <div className="menu-line-icon">
          <div className="line-1"></div>
          <div className="line-2"></div>
          <div className="line-3"></div>
        </div>
        <div className="nav-block">
          <p className="nav-header">Menu</p>
          <nav>
            <Link title='About Us' to="/#about">About</Link>
            <Link title='Product' to="/#products">Product</Link>
            <Link title='Services' to="/#services">Services</Link>
            <p role="presentation" onClick={() => openForm()}>Get In Touch</p>
          </nav>
        </div>
      </div>
    </HeaderBlock>
  </React.Fragment>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
